<template>
  <ValidationObserver ref="observer" tag="div" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validate)">
      <div class="w-full">
        <ValidationProvider v-slot="{ errors }" tag="div" rules="required" class="mb-2">
          <BaseSelect label="Select Slide" name="slide" :options="favs" v-model="slide_id" optionKey="id" optionValue="title" />
          <p class="text-sm text-red-600" v-if="errors[0]">{{ errors[0] }}</p>
        </ValidationProvider>
      </div>

      <BaseBtn type="submit" text="Add" />
      <FlashMessage :message="message" :error="error" />
    </form>
  </ValidationObserver>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  //setInteractionMode,
} from "vee-validate";
import { required } from "vee-validate/dist/rules";

import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Heading from "@tiptap/extension-heading";

extend("required", {
  ...required,
  message: "This field is required",
});

import { mapGetters } from "vuex";
import { getError } from "@/utils/helpers";
import BaseBtn from "@/components/BaseBtn";
import BaseInput from "@/components/BaseInput";
import SlideService from "@/services/SlideService";
import FlashMessage from "@/components/FlashMessage";
import BaseSelect from "@/components/BaseSelect";
import Fabric from "@/components/Fabric";

export default {
  name: "AddSlideFavs",
  components: {
    BaseBtn,
    BaseInput,
    BaseSelect,
    FlashMessage,
    Fabric,
    Editor,
    EditorContent,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    attributes: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      slide_id: "",
      error: null,
      message: null,
      favSlides: [],
    };
  },
  computed: {
    ...mapGetters("slide", ["add", "update", 'favs']),
    ...mapGetters("concept", ["slides"]),
  },
  methods: {
    async validate() {
      this.error = null;
      this.message = null;

      let slide = await SlideService.getSlide(this.slide_id).then((slide) => {
          let addSlide = slide.data.data
        addSlide.sort_order = Number(addSlide.sort_order) + 1;

        SlideService.add(addSlide)
          .then(() => {
            this.message = "Slide added.";
            this.$parent.close();
            this.$eventBus.$emit("slide:added", true);
          })
          .catch((error) => console.error(error));
      });
    },
  },
  async mounted() {
    if (this.attributes.concept_id) {
      await this.$store.dispatch("slide/getFavs")
    }
  },
};
</script>
